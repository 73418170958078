import * as React from 'react';
import {
    Create,
    Datagrid,
    DeleteButton,
    EditButton,
    List,
    TextField,
    TabbedForm,
    FormTab,
    ImageInput,
    ImageField,
    TextInput,
    Edit,
    SimpleShowLayout,
} from 'react-admin';
import SimpleArray from '../Utils/getImages';

import RichTextInput from 'ra-input-rich-text';
import { Box } from '@material-ui/core';

export const PlansList = (props) => (
    <List {...props}>
        <Datagrid rowClick='edit'>
            <TextField source='id' label='ID' />
            <TextField
                source='plansTitle_pl'
                label='Tytuł planu hodowlanego PL'
            />
            <EditButton basePath='/plans' label='Edytuj' />
            <DeleteButton basePath='/plans' label='Usuń' />
        </Datagrid>
    </List>
);

export const PlansEdit = (props) => (
    <Edit {...props}>
        <TabbedForm>
            <FormTab label='Język Polski'>
                <TextInput
                    source='plansTitle_pl'
                    label='Tytuł planu hodowlanego po PL'
                />
                <RichTextInput
                    source='plansDescription_pl'
                    label='Opis planu hodowlanego po Polsku'
                    toolbar={[
                        ['bold', 'italic', 'underline', 'strike'],
                        [{ list: 'ordered' }, { list: 'bullet' }],
                        [{ color: [] }, { background: [] }],
                        [{ align: [] }],
                        ['link'],
                        ['clean'],
                    ]}
                />
            </FormTab>
            <FormTab label='Język Angielski'>
                <TextInput
                    source='plansTitle_en'
                    label='Tytuł planu hodowlanego po EN'
                />
                <RichTextInput
                    source='plansDescription_en'
                    label='Opis planu hodowlanego po Angielsku'
                    toolbar={[
                        ['bold', 'italic', 'underline', 'strike'],
                        [{ list: 'ordered' }, { list: 'bullet' }],
                        [{ color: [] }, { background: [] }],
                        [{ align: [] }],
                        ['link'],
                        ['clean'],
                    ]}
                />
            </FormTab>
            <FormTab label='Zdjęcia'>
                <Box display='flex' fullWidth>
                    <Box flex='1'>
                        <ImageInput
                            source='gallery'
                            label='Gallery'
                            accept='image/*'
                            multiple
                            fullWidth
                        >
                            <ImageField source='src' title='title' />
                        </ImageInput>
                    </Box>
                </Box>
                <SimpleShowLayout fullWidth>
                    <SimpleArray source='images' label='Zdjęcia' />
                </SimpleShowLayout>
            </FormTab>
        </TabbedForm>
    </Edit>
);

export const PlansCreate = (props) => (
    <Create {...props}>
        <TabbedForm>
            <FormTab label='Język Polski'>
                <TextInput
                    source='plansTitle_pl'
                    label='Tytuł planu hodowlanego po PL'
                />
                <RichTextInput
                    source='plansDescription_pl'
                    label='Opis planu hodowlanego po Polsku'
                    toolbar={[
                        ['bold', 'italic', 'underline', 'strike'],
                        [{ list: 'ordered' }, { list: 'bullet' }],
                        [{ color: [] }, { background: [] }],
                        [{ align: [] }],
                        ['link'],
                        ['clean'],
                    ]}
                />
            </FormTab>
            <FormTab label='Język Angielski'>
                <TextInput
                    source='plansTitle_en'
                    label='Tytuł planu hodowlanego po EN'
                />
                <RichTextInput
                    source='plansDescription_en'
                    label='Opis planu hodowlanego po Angielsku'
                    toolbar={[
                        ['bold', 'italic', 'underline', 'strike'],
                        [{ list: 'ordered' }, { list: 'bullet' }],
                        [{ color: [] }, { background: [] }],
                        [{ align: [] }],
                        ['link'],
                        ['clean'],
                    ]}
                />
            </FormTab>
            <FormTab label='Zdjęcia'>
                <Box display='flex' fullWidth>
                    <Box flex='1'>
                        <ImageInput
                            source='gallery'
                            label='Gallery'
                            accept='image/*'
                            multiple
                            fullWidth
                        >
                            <ImageField source='src' title='title' />
                        </ImageInput>
                    </Box>
                </Box>
            </FormTab>
        </TabbedForm>
    </Create>
);
