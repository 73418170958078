import { Box } from '@material-ui/core';
import RichTextInput from 'ra-input-rich-text';
import React from 'react';
import PropTypes from 'prop-types';
import {
    Datagrid,
    DateInput,
    DeleteButton,
    Edit,
    EditButton,
    FormTab,
    List,
    TabbedForm,
    TextField,
    TextInput,
    SelectInput,
    ImageField,
    ImageInput,
    Create,
    required,
    DateField,
    SimpleShowLayout,
} from 'react-admin';
import SimpleArray from '../Utils/getImages';

export const LittersList = (props) => (
    <List {...props}>
        <Datagrid rowClick='edit'>
            <TextField source='id' label='ID' />
            <TextField source='litterName' label='Nazwa miotu' />
            <DateField source='litterBirth' label='Data urodzenia' />
            <TextField source='breed' label='Rasa' />
            <EditButton basePath='/litters' label='Edytuj' />
            <DeleteButton basePath='/litters' label='Usuń' />
        </Datagrid>
    </List>
);

export const LittersEdit = (props) => (
    <Edit {...props} title='Edytuj miot'>
        <TabbedForm>
            <FormTab label='Informacje o miocie'>
                <Box fullWidth>
                    <Box flex='1'>
                        <TextInput
                            source='litterName'
                            label='Nazwa miotu:'
                            fullWidth
                            validate={required()}
                        />
                    </Box>
                    <Box display='flex'>
                        <Box flex='1' mr='20px'>
                            <DateInput
                                source='litterBirth'
                                label='Data urodzenia:'
                                fullWidth
                                validate={required()}
                            />
                        </Box>
                        <Box flex='1' ml='20px'>
                            <SelectInput
                                source='breed'
                                label='Rasa miotu:'
                                choices={[
                                    {
                                        id: 'chinese-crested',
                                        name: 'Grzywacz Chiński',
                                    },
                                    { id: 'samoyed', name: 'Samoyed' },
                                    {
                                        id: 'miniature-american-shepherd',
                                        name: 'Miniaturowy owczarek amerykański',
                                    },
                                ]}
                                fullWidth
                                validate={required()}
                            />
                        </Box>
                    </Box>
                </Box>
            </FormTab>
            <FormTab label='Język Polski'>
                <RichTextInput
                    source='litterDescription_pl'
                    label='Opis miotu po Polsku'
                    toolbar={[
                        ['bold', 'italic', 'underline', 'strike'],
                        [{ list: 'ordered' }, { list: 'bullet' }],
                        [{ color: [] }, { background: [] }],
                        [{ align: [] }],
                        ['link'],
                        ['clean'],
                    ]}
                />
            </FormTab>
            <FormTab label='Język Angielski'>
                <RichTextInput
                    source='litterDescription_en'
                    label='Opis miotu po Angielsku'
                    toolbar={[
                        ['bold', 'italic', 'underline', 'strike'],
                        [{ list: 'ordered' }, { list: 'bullet' }],
                        [{ color: [] }, { background: [] }],
                        [{ align: [] }],
                        ['link'],
                        ['clean'],
                    ]}
                />
            </FormTab>
            <FormTab label='Zdjęcia'>
                <Box display='flex' fullWidth>
                    <Box flex='1' mr='10px'>
                        <ImageInput
                            source='mainImg'
                            label='Zdjęcie Główne'
                            accept='image/*'
                            fullWidth
                        >
                            <ImageField source='src' title='title' />
                        </ImageInput>
                    </Box>
                    <Box flex='2' ml='10px'>
                        <ImageInput
                            source='gallery'
                            label='Gallery'
                            accept='image/*'
                            multiple
                            fullWidth
                        >
                            <ImageField source='src' title='title' />
                        </ImageInput>
                    </Box>
                </Box>
                <SimpleShowLayout fullWidth>
                    <SimpleArray source='images' label='Zdjęcia' />
                </SimpleShowLayout>
            </FormTab>
        </TabbedForm>
    </Edit>
);

export const LittersCreate = (props) => (
    <Create {...props} title='Stwórz nowy miot'>
        <TabbedForm>
            <FormTab label='Informacje o miocie'>
                <Box fullWidth>
                    <Box flex='1'>
                        <TextInput
                            source='litterName'
                            label='Nazwa miotu:'
                            fullWidth
                            validate={required()}
                        />
                    </Box>
                    <Box display='flex'>
                        <Box flex='1' mr='20px'>
                            <DateInput
                                source='litterBirth'
                                label='Data urodzenia:'
                                fullWidth
                                validate={required()}
                            />
                        </Box>
                        <Box flex='1' ml='20px'>
                            <SelectInput
                                source='breed'
                                label='Rasa miotu:'
                                choices={[
                                    {
                                        id: 'chinese-crested',
                                        name: 'Grzywacz Chiński',
                                    },
                                    { id: 'samoyed', name: 'Samoyed' },
                                    {
                                        id: 'miniature-american-shepherd',
                                        name: 'Miniaturowy owczarek amerykański',
                                    },
                                ]}
                                fullWidth
                                validate={required()}
                            />
                        </Box>
                    </Box>
                </Box>
            </FormTab>
            <FormTab label='Język Polski'>
                <RichTextInput
                    source='litterDescription_pl'
                    label='Opis miotu po Polsku'
                    toolbar={[
                        ['bold', 'italic', 'underline', 'strike'],
                        [{ list: 'ordered' }, { list: 'bullet' }],
                        [{ color: [] }, { background: [] }],
                        [{ align: [] }],
                        ['link'],
                        ['clean'],
                    ]}
                />
            </FormTab>
            <FormTab label='Język Angielski'>
                <RichTextInput
                    source='litterDescription_en'
                    label='Opis miotu po Angielsku'
                    toolbar={[
                        ['bold', 'italic', 'underline', 'strike'],
                        [{ list: 'ordered' }, { list: 'bullet' }],
                        [{ color: [] }, { background: [] }],
                        [{ align: [] }],
                        ['link'],
                        ['clean'],
                    ]}
                />
            </FormTab>
            <FormTab label='Zdjęcia'>
                <Box display='flex' fullWidth>
                    <Box flex='1' mr='10px'>
                        <ImageInput
                            source='mainImg'
                            label='Zdjęcie Główne'
                            accept='image/*'
                            fullWidth
                            validate={required()}
                        >
                            <ImageField source='src' title='title' />
                        </ImageInput>
                    </Box>
                    <Box flex='2' ml='10px'>
                        <ImageInput
                            source='gallery'
                            label='Gallery'
                            accept='image/*'
                            multiple
                            fullWidth
                        >
                            <ImageField source='src' title='title' />
                        </ImageInput>
                    </Box>
                </Box>
            </FormTab>
        </TabbedForm>
    </Create>
);
