import { fetchUtils } from 'react-admin';
import restServerProvider from 'ra-data-json-server';

const servicesHost = 'https://api.amjuma.pl';

const httpClient = (url, options = {}) => {
    if (!options.headers) {
        options.headers = new Headers({ Accept: 'application/json' });
    }
    const token = localStorage.getItem('auth-token');
    // add your own headers here
    options.headers.set('auth-token', token);
    return fetchUtils.fetchJson(url, options);
};

const dataRestProvider = restServerProvider(servicesHost, httpClient);

let formData;

function getElements(resource, params) {
    formData = new FormData();
    switch (resource) {
        case 'news': {
            formData.append('newsTitle_pl', params.data.newsTitle_pl);
            formData.append('newsTitle_en', params.data.newsTitle_en);
            formData.append(
                'newsDescription_pl',
                params.data.newsDescription_pl
            );
            formData.append(
                'newsDescription_en',
                params.data.newsDescription_en
            );
            formData.append('newsEdited', Date.now());

            //images
            if (params.data.gallery) {
                params.data.gallery.forEach((item) => {
                    formData.append('gallery', item.rawFile);
                });
            }
            break;
        }
        case 'dogs': {
            formData.append('dogName', params.data.dogName);
            formData.append('breed', params.data.breed);
            formData.append('gender', params.data.gender);
            formData.append('country', params.data.country);
            formData.append('dogBirth', params.data.dogBirth);
            formData.append('dogDescription_pl', params.data.dogDescription_pl);
            formData.append('dogDescription_en', params.data.dogDescription_en);

            if (params.data.inMemory) {
                if (params.data.inMemory === true) {
                    formData.append('inMemory', 1);
                } else {
                    formData.append('inMemory', 0);
                }
            }

            //images
            if (params.data.mainImg) {
                formData.append('mainImg', params.data.mainImg.rawFile);
            }
            if (params.data.pedigree) {
                formData.append('pedigree', params.data.pedigree.rawFile);
            }
            if (params.data.gallery) {
                params.data.gallery.forEach((item) => {
                    formData.append('gallery', item.rawFile);
                });
            }
            break;
        }
        case 'litters': {
            formData.append('litterName', params.data.litterName);
            formData.append('litterBirth', params.data.litterBirth);
            formData.append('breed', params.data.breed);
            formData.append(
                'litterDescription_pl',
                params.data.litterDescription_pl
            );
            formData.append(
                'litterDescription_en',
                params.data.litterDescription_en
            );

            //images
            if (params.data.mainImg) {
                formData.append('mainImg', params.data.mainImg.rawFile);
            }
            if (params.data.gallery) {
                params.data.gallery.forEach((item) => {
                    formData.append('gallery', item.rawFile);
                });
            }
            break;
        }
        case 'hotel': {
            formData.append('description_pl', params.data.description_pl);
            formData.append('description_en', params.data.description_en);

            if (params.data.gallery) {
                params.data.gallery.forEach((item) => {
                    formData.append('gallery', item.rawFile);
                });
            }

            if (params.data.mainImg) {
                formData.append('mainImg', params.data.mainImg.rawFile);
            }
            break;
        }
        case 'grooming': {
            formData.append('description_pl', params.data.description_pl);
            formData.append('description_en', params.data.description_en);

            if (params.data.gallery) {
                params.data.gallery.forEach((item) => {
                    formData.append('gallery', item.rawFile);
                });
            }
            break;
        }

        case 'pricing': {
            formData.append('name', params.data.name);
            formData.append('name_en', params.data.name_en);
            formData.append('price', params.data.price);
            formData.append('category', params.data.category);

            break;
        }

        case 'plans': {
            formData.append(
                'plansDescription_pl',
                params.data.plansDescription_pl
            );
            formData.append(
                'plansDescription_en',
                params.data.plansDescription_en
            );
            formData.append('plansTitle_pl', params.data.plansTitle_pl);
            formData.append('plansTitle_en', params.data.plansTitle_en);
            formData.append('plansEdited', Date.now());

            if (params.data.gallery) {
                params.data.gallery.forEach((item) => {
                    formData.append('gallery', item.rawFile);
                });
            }
            break;
        }
        default: {
            break;
        }
    }
}

const myDataProvider = {
    ...dataRestProvider,
    create: (resource, params) => {
        getElements(resource, params);

        return httpClient(`${servicesHost}/${resource}`, {
            method: 'POST',
            body: formData,
        }).then(({ json }) => ({
            data: { ...params.data, id: json.id },
        }));
    },
    update: (resource, params) => {
        getElements(resource, params);

        return httpClient(`${servicesHost}/${resource}/${params.id}`, {
            method: 'PUT',
            body: formData,
        }).then(({ json }) => ({
            data: { ...params.data },
        }));
    },
};

export default myDataProvider;
