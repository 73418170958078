import * as React from 'react';
import {
    List,
    Datagrid,
    TextField,
    EditButton,
    Edit,
    TabbedForm,
    FormTab,
    ImageInput,
    ImageField,
    SimpleShowLayout,
} from 'react-admin';
import SimpleArray from '../Utils/getImages';

import RichTextInput from 'ra-input-rich-text';

export const GroomingList = (props) => (
    <List {...props}>
        <Datagrid rowClick='edit'>
            <TextField source='id' label='ID' />
            <EditButton basePath='/grooming' label='Edytuj' />
        </Datagrid>
    </List>
);

export const GroomingEdit = (props) => (
    <Edit title='Edytuj dane groomingu' {...props}>
        <TabbedForm>
            <FormTab label='Język Polski'>
                <RichTextInput
                    source='description_pl'
                    label='Opis groomingu po Polsku'
                    toolbar={[
                        ['bold', 'italic', 'underline', 'strike'],
                        [{ list: 'ordered' }, { list: 'bullet' }],
                        [{ color: [] }, { background: [] }],
                        [{ align: [] }],
                        ['link'],
                        ['clean'],
                    ]}
                />
            </FormTab>

            <FormTab label='Język Angielski'>
                <RichTextInput
                    source='description_en'
                    label='Opis groomingu po Angielsku'
                    toolbar={[
                        ['bold', 'italic', 'underline', 'strike'],
                        [{ list: 'ordered' }, { list: 'bullet' }],
                        [{ color: [] }, { background: [] }],
                        [{ align: [] }],
                        ['link'],
                        ['clean'],
                    ]}
                />
            </FormTab>

            <FormTab label='Zdjęcia'>
                <ImageInput
                    source='gallery'
                    label='Galeria'
                    accept='image/*'
                    multiple
                >
                    <ImageField source='src' title='title' />
                </ImageInput>

                <SimpleShowLayout fullWidth>
                    <SimpleArray source='images' label='Zdjęcia' />
                </SimpleShowLayout>
            </FormTab>
        </TabbedForm>
    </Edit>
);
