import * as React from 'react';
import { Admin, Resource } from 'react-admin';

import getDataProvider from './Config/dataProvider';
import getAuthProvider from './Config/authProvider';

//Import Tabs
import Dashboard from './Components/Dashboard/Dashboard';
import { NewsList, NewsEdit, NewsCreate } from './Components/News/News';
import { DogsEdit, DogsList, DogsCreate } from './Components/Dogs/Dogs';
import {
    LittersEdit,
    LittersList,
    LittersCreate,
} from './Components/Litters/Litters';
import { PlansList, PlansCreate, PlansEdit } from './Components/Plans/Plans';
import { HotelEdit, HotelList } from './Components/Hotel/hotel';
import { GroomingEdit, GroomingList } from './Components/Grooming/grooming';
import {
    PricingEdit,
    PricingList,
    PricingCreate,
} from './Components/Pricing/pricing';

const App = () => (
    <Admin
        dashboard={Dashboard}
        title='Dashbaord'
        dataProvider={getDataProvider}
        authProvider={getAuthProvider}
    >
        <Resource
            name='news'
            list={NewsList}
            edit={NewsEdit}
            create={NewsCreate}
        />
        <Resource
            name='dogs'
            list={DogsList}
            edit={DogsEdit}
            create={DogsCreate}
        />
        <Resource
            name='litters'
            list={LittersList}
            edit={LittersEdit}
            create={LittersCreate}
        />
        <Resource
            name='plans'
            list={PlansList}
            edit={PlansEdit}
            create={PlansCreate}
        />

        <Resource name='hotel' edit={HotelEdit} list={HotelList} />
        <Resource name='grooming' edit={GroomingEdit} list={GroomingList} />
        <Resource
            name='pricing'
            edit={PricingEdit}
            list={PricingList}
            create={PricingCreate}
        />
    </Admin>
);

export default App;
