import * as React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { Title } from 'react-admin';
export default () => (
    <Card>
        <Title title='Dashboard admin' />
        <CardContent>Lorem ipsum sic dolor amet...</CardContent>
    </Card>
);
