import React from 'react';
import {
    Datagrid,
    DeleteButton,
    Edit,
    EditButton,
    List,
    TextField,
    TextInput,
    SelectInput,
    Create,
    SimpleForm,
    required,
} from 'react-admin';

export const PricingList = (props) => (
    <List {...props}>
        <Datagrid rowClick='edit'>
            <TextField source='id' label='ID' />
            <TextField source='name' label='Nazwa' />
            <TextField source='price' label='Cena' />
            <TextField source='category' label='Kategoria' />
            <EditButton basePath='/pricing' label='Edytuj' />
            <DeleteButton basePath='/pricing' label='Usuń' />
        </Datagrid>
    </List>
);

export const PricingEdit = (props) => (
    <Edit {...props} title='Edytuj cennik'>
        <SimpleForm>
            <TextInput
                source='name'
                label='Nazwa po polsku'
                validate={required()}
            />
            <TextInput
                source='name_en'
                label='Nazwa po Angielsku'
                validate={required()}
            />
            <TextInput source='price' label='Cena' validate={required()} />
            <SelectInput
                validate={required()}
                source='category'
                label='Kategoria'
                choices={[
                    { id: 'grooming', name: 'Grooming' },
                    { id: 'hotel', name: 'Hotel' },
                ]}
            />
        </SimpleForm>
    </Edit>
);

export const PricingCreate = (props) => (
    <Create {...props} title='Stwórz nowy cennik'>
        <SimpleForm>
            <TextInput
                source='name'
                label='Nazwa po polsku'
                validate={required()}
            />
            <TextInput
                source='name_en'
                label='Nazwa po Angielsku'
                validate={required()}
            />
            <TextInput source='price' label='Cena' validate={required()} />
            <SelectInput
                validate={required()}
                source='category'
                label='Kategoria'
                choices={[
                    { id: 'grooming', name: 'Grooming' },
                    { id: 'hotel', name: 'Hotel' },
                ]}
            />
        </SimpleForm>
    </Create>
);
