import * as React from 'react';
import {
    List,
    Datagrid,
    TextField,
    EditButton,
    DeleteButton,
    Edit,
    TabbedForm,
    FormTab,
    TextInput,
    Create,
    ImageInput,
    ImageField,
    SimpleShowLayout,
} from 'react-admin';
import SimpleArray from '../Utils/getImages';

import RichTextInput from 'ra-input-rich-text';

const validateNewsCreation = (values) => {
    const errors = {};
    if (!values.newsTitle_pl) {
        errors.newsTitle_pl = 'Pole wymagane!';
    }
    if (!values.newsTitle_en) {
        errors.newsTitle_en = 'Pole wymagane!';
    }
    return errors;
};

export const NewsList = (props) => (
    <List {...props}>
        <Datagrid rowClick='edit'>
            <TextField source='id' label='ID' />
            <TextField source='newsTitle_pl' label='Tytuł' />
            <EditButton basePath='/news' label='Edytuj' />
            <DeleteButton basePath='/news' label='Usuń' />
        </Datagrid>
    </List>
);

export const NewsEdit = (props) => (
    <Edit title="Edytuj news'a" {...props}>
        <TabbedForm>
            <FormTab label='Język Polski'>
                <TextInput source='newsTitle_pl' />
                <RichTextInput
                    source='newsDescription_pl'
                    label='Opis news po Polsku'
                    toolbar={[
                        ['bold', 'italic', 'underline', 'strike'],
                        [{ list: 'ordered' }, { list: 'bullet' }],
                        [{ color: [] }, { background: [] }],
                        [{ align: [] }],
                        ['link'],
                        ['clean'],
                    ]}
                />
            </FormTab>
            <FormTab label='Język Angielski'>
                <TextInput source='newsTitle_en' />
                <RichTextInput
                    source='newsDescription_en'
                    label='Opis news po Angielsku'
                    toolbar={[
                        ['bold', 'italic', 'underline', 'strike'],
                        [{ list: 'ordered' }, { list: 'bullet' }],
                        [{ color: [] }, { background: [] }],
                        [{ align: [] }],
                        ['link'],
                        ['clean'],
                    ]}
                />
            </FormTab>
            <FormTab label='Zdjęcia'>
                <ImageInput
                    source='gallery'
                    label='Gallery'
                    accept='image/*'
                    multiple
                >
                    <ImageField source='src' title='title' />
                </ImageInput>

                <SimpleShowLayout fullWidth>
                    <SimpleArray source='images' label='Zdjęcia' />
                </SimpleShowLayout>
            </FormTab>
        </TabbedForm>
    </Edit>
);

export const NewsCreate = (props) => (
    <Create title="Utwórz nowego news'a" {...props}>
        <TabbedForm validate={validateNewsCreation}>
            <FormTab label='Język Polski'>
                <TextInput source='newsTitle_pl' label='Tytuł' />
                <RichTextInput
                    source='newsDescription_pl'
                    label='Treść newsa po Polsku'
                    toolbar={[
                        ['bold', 'italic', 'underline', 'strike'],
                        [{ list: 'ordered' }, { list: 'bullet' }],
                        [{ color: [] }, { background: [] }],
                        [{ align: [] }],
                        ['link'],
                        ['clean'],
                    ]}
                />
            </FormTab>
            <FormTab label='Język Angielski'>
                <TextInput source='newsTitle_en' label='Tytuł' />
                <RichTextInput
                    source='newsDescription_en'
                    label='Treść newsa po Angielsku'
                    toolbar={[
                        ['bold', 'italic', 'underline', 'strike'],
                        [{ list: 'ordered' }, { list: 'bullet' }],
                        [{ color: [] }, { background: [] }],
                        [{ align: [] }],
                        ['link'],
                        ['clean'],
                    ]}
                />
            </FormTab>
            <FormTab label='Zdjęcia'>
                <ImageInput
                    source='gallery'
                    label='Gallery'
                    accept='image/*'
                    multiple
                >
                    <ImageField source='src' title='title' />
                </ImageInput>
            </FormTab>
        </TabbedForm>
    </Create>
);
