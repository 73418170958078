import * as React from 'react';
import {
    List,
    Datagrid,
    TextField,
    EditButton,
    Edit,
    TabbedForm,
    FormTab,
    ImageInput,
    ImageField,
    SimpleShowLayout,
} from 'react-admin';
import SimpleArray from '../Utils/getImages';

import RichTextInput from 'ra-input-rich-text';
import { Box } from '@material-ui/core';

export const HotelList = (props) => (
    <List {...props}>
        <Datagrid rowClick='edit'>
            <TextField source='id' label='ID' />
            <EditButton basePath='/hotel' label='Edytuj' />
        </Datagrid>
    </List>
);

export const HotelEdit = (props) => (
    <Edit title='Edytuj dane hotelu' {...props}>
        <TabbedForm>
            <FormTab label='Język Polski'>
                <RichTextInput
                    source='description_pl'
                    label='Opis hotelu po Polsku'
                    toolbar={[
                        ['bold', 'italic', 'underline', 'strike'],
                        [{ list: 'ordered' }, { list: 'bullet' }],
                        [{ color: [] }, { background: [] }],
                        [{ align: [] }],
                        ['link'],
                        ['clean'],
                    ]}
                />
            </FormTab>
            <FormTab label='Język Angielski'>
                <RichTextInput
                    source='description_en'
                    label='Opis hotelu po Angielsku'
                    toolbar={[
                        ['bold', 'italic', 'underline', 'strike'],
                        [{ list: 'ordered' }, { list: 'bullet' }],
                        [{ color: [] }, { background: [] }],
                        [{ align: [] }],
                        ['link'],
                        ['clean'],
                    ]}
                />
            </FormTab>
            <FormTab label='Zdjęcia'>
                <Box display='flex' fullWidth>
                    <Box flex='1' mx='10px'>
                        <ImageInput
                            source='mainImg'
                            label='Zdjęcie główne'
                            accept='image/*'
                        >
                            <ImageField source='src' title='title' />
                        </ImageInput>
                    </Box>
                    <Box flex='1' mx='10px'>
                        <ImageInput
                            source='gallery'
                            label='Galeria'
                            accept='image/*'
                            multiple
                        >
                            <ImageField source='src' title='title' />
                        </ImageInput>
                    </Box>
                </Box>
                <SimpleShowLayout fullWidth>
                    <SimpleArray source='images' label='Zdjęcia' />
                </SimpleShowLayout>
            </FormTab>
        </TabbedForm>
    </Edit>
);
