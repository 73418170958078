import React from 'react';
import {
    Datagrid,
    DateInput,
    DeleteButton,
    Edit,
    EditButton,
    FormTab,
    List,
    SelectInput,
    TabbedForm,
    TextField,
    TextInput,
    Create,
    ImageInput,
    ImageField,
    required,
    SimpleShowLayout,
    BooleanField,
    BooleanInput,
    FunctionField,
} from 'react-admin';
import SimpleArray from '../Utils/getImages';
import RichTextInput from 'ra-input-rich-text';
import { Box } from '@material-ui/core';

export const DogsList = (props) => (
    <List {...props}>
        <Datagrid rowClick='edit'>
            <TextField source='id' label='ID' />
            <TextField source='dogName' label='Imię' />
            <TextField source='gender' label='Płeć' />
            <TextField source='breed' label='Rasa' />
            <FunctionField
                source='inMemory'
                label='W Pamięci'
                render={(record, source) => (
                    <BooleanField
                        record={{ ...record, inMemory: !!record.inMemory }}
                        source={source}
                    />
                )}
            />

            <EditButton label='Edytuj' />
            <DeleteButton label='Usuń' />
        </Datagrid>
    </List>
);

export const DogsEdit = (props) => (
    <Edit {...props} title='Edytuj psa'>
        <TabbedForm>
            <FormTab label='Dane psa'>
                <Box display='flex' fullWidth>
                    <Box flex='1' mx='10px'>
                        <TextInput
                            source='dogName'
                            label='Nazwa psa'
                            fullWidth
                        />
                    </Box>
                    <Box flex='1' mx='10px'>
                        <DateInput
                            source='dogBirth'
                            label='Data urodzenia'
                            fullWidth
                        />
                    </Box>
                    <Box flex='1' mx='10px'>
                        <TextInput
                            source='country'
                            label='Kraj pochodzenia'
                            fullWidth
                        />
                    </Box>
                </Box>

                <Box display='flex' fullWidth>
                    <Box flex='1' mx='10px'>
                        <SelectInput
                            source='breed'
                            label='Rasa'
                            choices={[
                                {
                                    id: 'chinese-crested',
                                    name: 'Grzywacz Chiński',
                                },
                                { id: 'samoyed', name: 'Samoyed' },
                                {
                                    id: 'miniature-american-shepherd',
                                    name: 'Miniaturowy owczarek amerykański',
                                },
                            ]}
                            fullWidth
                        />
                    </Box>
                    <Box flex='1' mx='10px'>
                        <SelectInput
                            source='gender'
                            label='Płeć'
                            choices={[
                                { id: 'male', name: 'Pies' },
                                { id: 'female', name: 'Suka' },
                            ]}
                            fullWidth
                        />
                    </Box>
                    <Box flex='1' mx='10px'>
                        <BooleanInput
                            source='inMemory'
                            label='W Pamięci'
                            fullWidth
                        />
                    </Box>
                </Box>
            </FormTab>
            <FormTab label='Język Polski'>
                <RichTextInput
                    source='dogDescription_pl'
                    label='Opis psa po Polsku'
                    toolbar={[
                        ['bold', 'italic', 'underline', 'strike'],
                        [{ list: 'ordered' }, { list: 'bullet' }],
                        [{ color: [] }, { background: [] }],
                        [{ align: [] }],
                        ['link'],
                        ['clean'],
                    ]}
                />
            </FormTab>
            <FormTab label='Język Angielski'>
                <RichTextInput
                    source='dogDescription_en'
                    label='Opis psa po Angielsku'
                    toolbar={[
                        ['bold', 'italic', 'underline', 'strike'],
                        [{ list: 'ordered' }, { list: 'bullet' }],
                        [{ color: [] }, { background: [] }],
                        [{ align: [] }],
                        ['link'],
                        ['clean'],
                    ]}
                />
            </FormTab>
            <FormTab label='Zdjęcia'>
                <Box display='flex' fullWidth>
                    <Box flex='1' mr='20px'>
                        <ImageInput
                            source='mainImg'
                            label='List'
                            accept='image/*'
                        >
                            <ImageField source='src' title='title' />
                        </ImageInput>
                    </Box>
                    <Box flex='2' ml='20px'>
                        <ImageInput
                            source='gallery'
                            label='Gallery'
                            accept='image/*'
                            multiple
                        >
                            <ImageField source='src' title='title' />
                        </ImageInput>
                    </Box>
                </Box>
                <SimpleShowLayout fullWidth>
                    <SimpleArray source='images' label='Zdjęcia' />
                </SimpleShowLayout>
            </FormTab>
        </TabbedForm>
    </Edit>
);

export const DogsCreate = (props) => (
    <Create {...props} title='Dodaj nowego psa'>
        <TabbedForm>
            <FormTab label='Dane psa'>
                <Box display='flex' fullWidth>
                    <Box flex='1' mx='10px'>
                        <TextInput
                            source='dogName'
                            label='Nazwa psa'
                            fullWidth
                        />
                    </Box>
                    <Box flex='1' mx='10px'>
                        <DateInput
                            source='dogBirth'
                            label='Data urodzenia'
                            fullWidth
                        />
                    </Box>
                    <Box flex='1' mx='10px'>
                        <TextInput
                            source='country'
                            label='Kraj pochodzenia'
                            fullWidth
                        />
                    </Box>
                </Box>

                <Box display='flex' fullWidth>
                    <Box flex='1' mx='10px'>
                        <SelectInput
                            source='breed'
                            label='Rasa'
                            choices={[
                                {
                                    id: 'chinese-crested',
                                    name: 'Grzywacz Chiński',
                                },
                                { id: 'samoyed', name: 'Samoyed' },
                                {
                                    id: 'miniature-american-shepherd',
                                    name: 'Miniaturowy owczarek amerykański',
                                },
                            ]}
                            fullWidth
                        />
                    </Box>
                    <Box flex='1' mx='10px'>
                        <SelectInput
                            source='gender'
                            label='Płeć'
                            choices={[
                                { id: 'male', name: 'Pies' },
                                { id: 'female', name: 'Suka' },
                            ]}
                            fullWidth
                        />
                    </Box>
                    <Box flex='1' mx='10px'>
                        <BooleanInput
                            source='inMemory'
                            label='W Pamięci'
                            fullWidth
                        />
                    </Box>
                </Box>
            </FormTab>
            <FormTab label='Język Polski'>
                <RichTextInput
                    source='dogDescription_pl'
                    label='Opis psa po Polsku'
                    toolbar={[
                        ['bold', 'italic', 'underline', 'strike'],
                        [{ list: 'ordered' }, { list: 'bullet' }],
                        [{ color: [] }, { background: [] }],
                        [{ align: [] }],
                        ['link'],
                        ['clean'],
                    ]}
                />
            </FormTab>
            <FormTab label='Język Angielski'>
                <RichTextInput
                    source='dogDescription_en'
                    label='Opis psa po Angielsku'
                    toolbar={[
                        ['bold', 'italic', 'underline', 'strike'],
                        [{ list: 'ordered' }, { list: 'bullet' }],
                        [{ color: [] }, { background: [] }],
                        [{ align: [] }],
                        ['link'],
                        ['clean'],
                    ]}
                />
            </FormTab>
            <FormTab label='Zdjęcia'>
                <Box display='flex' fullWidth>
                    <Box flex='1' mr='20px'>
                        <ImageInput
                            source='mainImg'
                            label='List'
                            accept='image/*'
                            validate={required()}
                        >
                            <ImageField source='src' title='title' />
                        </ImageInput>
                    </Box>
                    <Box flex='2' ml='20px'>
                        <ImageInput
                            source='gallery'
                            label='Gallery'
                            accept='image/*'
                            multiple
                        >
                            <ImageField source='src' title='title' />
                        </ImageInput>
                    </Box>
                </Box>
            </FormTab>
        </TabbedForm>
    </Create>
);
